<template>
  <div style="height: inherit">
    <swiper
      v-if="theAlerts !== null && theAlerts.length > 0"
      class="swiper-paginations"
      :options="swiperOptions"
      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
    >
      <swiper-slide
        v-for="(item,index) in theAlerts"
        :key="index"
      >
        <b-card
          v-if="item.is_shown === true"
        >
          <b-card-body
            class="ml-3 mr-3 mb-2"
          >
            <!-- eslint-disable-next-line vue/no-v-html -->
            <span v-html="item.detail.replace(/\n/g, '<br>')" />
            <br><br>
            Created: {{ item.creation_date }}
          </b-card-body>
        </b-card>
      </swiper-slide>
      <!-- Add Arrows -->
      <div
        slot="button-next"
        class="swiper-button-next mr-1"
      />
      <div
        slot="button-prev"
        class="swiper-button-prev ml-1"
      />
    </swiper>
    <section
      :class="itemView"
    >
      <b-card
        v-for="(item, index) in items"
        :key="item.id"
        class="ecommerce-card"
        no-body
      >
        <div
          v-if="$can('create', 'reports')"
          class="text-center"
        >
          <b-link @click="addNewReport(item)">
            <b-img
              :alt="`${item.label}`"
              fluid
              class="card-img-top"
              :src="imageIcons[index]"
            />
          </b-link>
        </div>
        <div
          v-else
          class="text-center"
        >
          <b-link @click="listReportsByType(item)">
            <b-img
              :alt="`${item.label}`"
              fluid
              class="card-img-top"
              :src="imageIcons[index]"
            />
          </b-link>
        </div>
        <!-- body -->
        <b-card-body>
          <h6
            class="item-name"
            style="margin-bottom: 0.75rem"
          >
            {{ item.label }}
          </h6>
        </b-card-body>
        <!-- Actions -->
        <div class="item-options text-center">
          <b-button
            variant="light"
            tag="a"
            class="btn-wishlist"
            @click="listReportsByType(item)"
          >
            <span>List</span>
          </b-button>
          <b-button
            v-if="$can('create', 'reports')"
            variant="primary"
            tag="a"
            class="btn-cart"
            @click="addNewReport(item)"
          >
            <span>Add Report</span>
          </b-button>
        </div>
      </b-card>
    </section>
  </div>
</template>

<script>
import {
  BCard, BCardBody, BImg, BLink, BButton,
} from 'bootstrap-vue'
import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api'
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'

// eslint-disable-next-line camelcase
import alarm_response from '@/assets/images/reports/alarm_response.jpg'
// eslint-disable-next-line camelcase
import general_occurrence from '@/assets/images/reports/general_occurrence.jpg'
// eslint-disable-next-line camelcase
import internal_useof_force from '@/assets/images/reports/internal_useof_force.jpg'
// eslint-disable-next-line camelcase
import parking_enforcement from '@/assets/images/reports/parking_enforcement.jpg'
// eslint-disable-next-line camelcase
import parking_permits from '@/assets/images/reports/parking_permits.jpg'
// eslint-disable-next-line camelcase
import security_log from '@/assets/images/reports/security_log.jpg'
// eslint-disable-next-line camelcase
import trespass_notice from '@/assets/images/reports/trespass_notice.jpg'
// eslint-disable-next-line camelcase
import vehicle_log from '@/assets/images/reports/vehicle_log.jpg'

import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import storeModule from '../../alerts/storeModule'
import ability from '../../../../libs/acl/ability'

export default {
  components: {
    BCard, BCardBody, BImg, BLink, BButton, Swiper, SwiperSlide,
  },
  setup() {
    const toast = useToast()
    const items = ref([])
    const theAlerts = ref(null)
    const itemView = 'grid-view'
    const itemViewOptions = [
      { icon: 'GridIcon', value: 'grid-view' },
      { icon: 'ListIcon', value: 'list-view' },
    ]

    items.value = store.state.appSettings.report_types

    // Alerts related items
    // Register module
    const STORE_MODULE_NAME = 'app-alerts'
    if (!store.hasModule(STORE_MODULE_NAME)) store.registerModule(STORE_MODULE_NAME, storeModule)
    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(STORE_MODULE_NAME)) store.unregisterModule(STORE_MODULE_NAME)
    })

    return {
      toast,
      items,
      theAlerts,
      itemView,
      itemViewOptions,
    }
  },
  data() {
    return {
      // eslint-disable-next-line global-require, camelcase
      imageIcons: [alarm_response, general_occurrence, internal_useof_force, parking_enforcement, parking_permits, security_log, trespass_notice, vehicle_log],
      swiperOptions: {
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
        pagination: {
          el: '.swiper-pagination',
        },
      },
    }
  },
  created() {
    console.log('fetchListItems')
    if (ability.can('read', 'alerts')) {
      console.log('PASSED')
      const qvars = {
        forceRefresh: false,
        q: null,
        perPage: 1000,
        page: 1,
        sortBy: 'creation_date',
        sortDesc: true,
      }
      console.log(qvars)
      store
        .dispatch('app-alerts/fetchItems', qvars)
        .then(response => {
          console.log(response)
          const tmp = []
          try {
            response.data.theItems.forEach(item => {
              if (item.is_shown === true) {
                tmp.push(item)
              }
            })
          } catch (error) {
            console.log(error)
          }
          console.log(this.theAlerts)
          this.theAlerts = tmp
          console.log(this.theAlerts)
        })
        .catch(error => {
          console.log(error) // No current user
          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Error fetching list',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    }
  },
  methods: {
    listReportsByType(item) {
      const reportType = item.value
      console.log('listReportsByType')
      console.log(reportType)
      const nextRout = { name: 'apps-reports-list', params: { currentReportType: reportType } }
      // Route user
      this.$router.replace(nextRout)
      // apps-reports-list
    },
    addNewReport(item) {
      const reportType = item.value
      console.log(reportType)
      const nextRout = { name: `apps-reports-${reportType}`, params: { currentReportType: reportType } }
      // Route user
      this.$router.replace(nextRout)
      // apps-reports-list
    },
  },
}
</script>

<style lang="scss">
@import "~@core/scss/base/pages/app-ecommerce.scss";
</style>

<style lang="scss" scoped>
.item-view-radio-group ::v-deep {
  .btn {
    display: flex;
    align-items: center;
  }
}
.swiper-button-prev, .swiper-button-next {
  color: #fff !important
}
</style>
